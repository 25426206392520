import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './vuex'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history', //removes # from url
    base: process.env.VUE_APP_URL_PREFIX,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../views/Home.vue')
        },
        {
            path: '/project/list',
            name: 'project/list',
            component: () => import('../views/project/List')
        },

        {
            path: '/project/add',
            name: 'project/add',
            component: () => import('../views/project/Add.vue')
        },
        {
            path: '/project/edit/:id',
            name: 'project/edit',
            component: () => import('../views/project/Edit.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../views/Login.vue')
        },
        {
            path: '/user/list',
            name: 'user/list',
            component: () => import('../views/user/List.vue')
        },
        {
            path: '/user/add',
            name: 'user/add',
            component: () => import('../views/user/Add.vue')
        },
        {
            path: '/user/edit/:id',
            name: 'user/edit',
            component: () => import('../views/user/Edit.vue')
        },
        {
            path: '/vendor/list',
            name: 'vendor/list',
            component: () => import('../views/vendor/List.vue')
        },
        {
            path: '/vendor/add',
            name: 'vendor/add',
            component: () => import('../views/vendor/Add.vue')
        },
        {
            path: '/vendor/edit/:id',
            name: 'vendor/edit',
            component: () => import('../views/vendor/Edit.vue')
        },
        {
            path: '/country/list',
            name: 'country/list',
            component: () => import('../views/country/List.vue')
        },
        {
            path: '/country/add',
            name: 'country/add',
            component: () => import('../views/country/Add')
        },
        {
            path: '/country/edit/:id',
            name: 'country/edit',
            component: () => import('../views/country/Edit.vue')
        },
        {
            path: '/sector/list',
            name: 'sector/list',
            component: () => import('../views/sector/List.vue')
        },
        {
            path: '/sector/add',
            name: 'sector/add',
            component: () => import('../views/sector/Add.vue')
        },
        {
            path: '/sector/edit/:id',
            name: 'sector/edit',
            component: () => import('../views/sector/Edit.vue')
        },
        {
            path: '/sector-application/list',
            name: 'sector-application/list',
            component: () => import('../views/sector-application/List.vue')
        },
        {
            path: '/sector-application/add',
            name: 'sector-application/add',
            component: () => import('../views/sector-application/Add.vue')
        },
        {
            path: '/sector-application/edit/:id',
            name: 'sector-application/edit',
            component: () => import('../views/sector-application/Edit.vue')
        },
        {
            path: '/product/list',
            name: 'product/list',
            component: () => import('../views/product/List.vue')
        },
        {
            path: '/product/add',
            name: 'product/add',
            component: () => import('../views/product/Add.vue')
        },
        {
            path: '/product/edit/:id',
            name: 'product/edit',
            component: () => import('../views/product/Edit.vue')
        },
    ]  
});

router.beforeEach((to, from, next) => {
    //redirect non loged users
    if(to.name !== 'login' && !store.getters.isUserAuthenticated) {
        next({name: 'login'})
    } else {
        next()
    }
});


export default router;