import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './plugins/vuex';
import router from './plugins/router';
import globals from './plugins/globals';
import axios from 'axios'
import moment from 'moment'
import i18n from './plugins/i18n'
import FlagIcon from 'vue-flag-icon'

Vue.use(FlagIcon);
Vue.prototype.moment = moment

require('@/assets/styles/main.css')

store.dispatch('initialiseStore')
  .then(function () {
    //axios global header can only be initialized AFTER store is loaded
    axios.defaults.withCredentials = true

    let http = axios.create({
      baseURL: globals.api.endpoint,
      /*headers: {
        //'Access-Control-Allow-Origin': 'http://localhost/',
        //'Access-Control-Allow-Credentials': true,
        //'Access-Control-Allow-Headers': '*',

      }*/
    })

    http.interceptors.request.use(
      (config) => {
        let token = store.getters.getUserData.api_token

        if (token) {
          config.headers['Authorization'] = `Bearer ${store.getters.getUserData.api_token}`
        }

        return config
      },

      (error) => {
        return Promise.reject(error)
      }
    )

    Vue.config.productionTip = false
    Vue.prototype.$axios = http
    Vue.prototype.$globals = globals

    Vue.component(
      'ErrorHandler', () => import('./components/ErrorHandler')
    )

    new Vue({
      vuetify,
      store,
      router,
      axios,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  })

