import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'
import globals from './globals';

Vue.use(Vuex)

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
  }
  return "";
}

const store = new Vuex.Store({
  state: {
    applicationData: null,
    user: {
      isAuthenticated: false,
      data: '', //data is a string type as we jigle between local storage (only supports strings) and json getter will return object
    },
  },
  mutations: {
    login(state, payload) {
      state.user.isAuthenticated = true;
      state.user.data = JSON.stringify(payload);

      document.cookie = `isAuthenticated=true;domain=${process.env.VUE_APP_COOKIE_DOMAIN};SameSite=None;Secure`;
      document.cookie = `userData=${JSON.stringify(payload)};domain=${process.env.VUE_APP_COOKIE_DOMAIN};SameSite=None;Secure`;
    },
    logout(state) {
      state.user.isAuthenticated = false;
      state.user.data = null;

      document.cookie = `isAuthenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.VUE_APP_COOKIE_DOMAIN};SameSite=None;Secure`;
      document.cookie = `userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${process.env.VUE_APP_COOKIE_DOMAIN};SameSite=None;Secure`;
    },
    initialiseStore(state) {
      if (getCookie('isAuthenticated') != "") {
        state.user.isAuthenticated = getCookie('isAuthenticated');
      }
      if (getCookie('userData') != "") {
        state.user.data = getCookie('userData');
      }
    },
    setApplicationData(state, data){
      state.applicationData = data
    }
  },
  getters: {
    getUserData(state) {
      if (state.user.isAuthenticated)
        return JSON.parse(state.user.data);
      else
        return {}
    },
    isUserAuthenticated(state) {
      return state.user.isAuthenticated;
    },
    getApplicationData(state) {
      return state.applicationData
    }
  },
  actions: {
    initialiseStore({ commit }) {
      return Promise.all([
        commit('initialiseStore')
      ])
    },
    loadApplicationData({ commit, getters }) {
      let url = globals.api.endpoint + globals.api.general.app.data

      if(getters.getUserData.id) {
        url += '/' + getters.getUserData.id
      }      

      axios({
        method: "get",
        url:  url,
      })
      .then((response) => {
        commit('setApplicationData', response.data.data)
      })
      .catch((error) => {
        console.log(error)
      });
    }

  }
});

export default store;