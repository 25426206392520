<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app v-if="isAuthenticated">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title"> {{ $t('app.title') }} </v-list-item-title>
          <v-list-item-subtitle> {{ $t('app.subtitle') }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item :key="'Dashboard'" link>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="{ name: 'home' }">
              <v-list-item-title>{{ $t('app.menu.dashboard') }}</v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :key="'Projects'" link>
          <v-list-item-icon>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="{ name: 'project/list' }">
              <v-list-item-title>{{ $t('app.menu.projects') }}</v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>

        <v-list-group :value="false" prepend-icon="mdi-shape-plus" v-if="isUserAdmin">
          <template v-slot:activator>
            <v-list-item-title>{{ $t('app.menu.appSettings') }}</v-list-item-title>
          </template>

          <v-list-group
            no-action
            sub-group
            :value="false"
            prepend-icon="mdi-account-search"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t('app.menu.userGroups') }}</v-list-item-title>
            </template>

            <v-list-item :key="'Users'" link>
              <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="{ name: 'user/list' }">
                  <v-list-item-title>{{ $t('app.menu.users') }}</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :key="'Vendors'" link>
              <v-list-item-icon>
                <v-icon>mdi-store</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="{ name: 'vendor/list' }">
                  <v-list-item-title>{{ $t('app.menu.vendors') }}</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action
            sub-group
            :value="false"
            prepend-icon="mdi-cube-outline"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ $t('app.menu.applications') }}</v-list-item-title>
            </template>

            <v-list-item :key="'Sectors'" link>
              <v-list-item-icon>
                <v-icon>mdi-cube-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="{ name: 'sector/list' }">
                  <v-list-item-title>{{ $t('app.menu.sectors') }}</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :key="'Sector applications'" link>
              <v-list-item-icon>
                <v-icon>mdi-cube-unfolded</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <router-link :to="{ name: 'sector-application/list' }">
                  <v-list-item-title>{{ $t('app.menu.sectorApplications') }}</v-list-item-title>
                </router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item :key="'Country'" link>
            <v-list-item-icon>
              <v-icon>mdi-earth</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <router-link :to="{ name: 'country/list' }">
                <v-list-item-title>{{ $t('app.menu.countries') }}</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :key="'Products'" link>
            <v-list-item-icon>
              <v-icon>mdi-barcode</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <router-link :to="{ name: 'product/list' }">
                <v-list-item-title>{{ $t('app.menu.products') }}</v-list-item-title>
              </router-link>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item :key="'Configurator'" link>
          <v-list-item-icon>
            <v-icon>mdi-checkerboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <a :href="configuratorUrl" target="_blank">
              <v-list-item-title>{{ $t('app.menu.configurator') }}</v-list-item-title>
            </a>
          </v-list-item-content>
        </v-list-item>

        <!--
          Cooment out for now if needed for testing
        <v-list-item :key="'Login'" link>
          <v-list-item-icon>
            <v-icon>mdi-account-arrow-right-outline</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <router-link :to="{ name: 'login' }">
              <v-list-item-title>Login</v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        -->
      </v-list>
      <template v-slot:append>
        <div class="pa-2 text-caption">
          Version: {{ version }}
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app dense v-if="isAuthenticated">
      <v-icon color="#264996" @click="drawer = !drawer">
        {{ drawer ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right' }}
      </v-icon>
      <!--
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      -->
      <v-spacer></v-spacer>
      {{ $t('app.menu.helloUser' , {name: getUserFirstnameCapitalised}) }}
      <v-menu bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon color="success">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout()">
            <v-list-item-title>
              <v-icon>mdi-account</v-icon>
              {{ $t('app.menu.logout') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'JetfloatCRM',
  data: () => ({
    drawer: null,
    configuratorUrl: process.env.VUE_APP_CONFIGURATOR_URL,
    version: process.env.VUE_APP_COMMIT_HASH
  }),
  created () {},
  computed: {
    isUserAdmin() {
      return this.$store.getters.getUserData.admin
    },
    isAuthenticated () {
      return this.$store.getters.isUserAuthenticated
    },
    getUserFirstnameCapitalised () {
      return (
        this.$store.getters.getUserData.name.charAt(0).toUpperCase() +
        this.$store.getters.getUserData.name.slice(1)
      )
    }
  },
  methods: {
    logout () {
      this.$store.commit('logout')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
