const globals = {
    api: {
        endpoint: process.env.VUE_APP_BACKEND_URL,
        general: {
            app: {
                data: 'general-application-data',
                uploadFile: 'upload-file',
                downloadProjectFile: 'download-project-file/',
            },
        },
        home: {
            getStatistics: "get-statistics/",
        },
        user: {
            login: "users/login",
            get: "users/",
            getAll: "users/all",
            create: "users/create",
            update: "users/update/",
            delete: "users/delete/",
        },
        vendors: {
            get: "vendors/",
            getAll: "vendors/all",
            create: "vendors/create",
            update: "vendors/update/",
            delete: "vendors/delete/",
        },
        country: {
            get: "countries/",
            getAll: "countries/all",
            create: "countries/create",
            update: "countries/update/",
            delete: "countries/delete/",
        },
        sector: {
            get: "sectors/",
            getAll: "sectors/all",
            create: "sectors/create",
            update: "sectors/update/",
            delete: "sectors/delete/",
        },
        sectorApplication: {
            get: "sector-applications/",
            getAll: "sector-applications/all",
            create: "sector-applications/create",
            update: "sector-applications/update/",
            delete: "sector-applications/delete/",
        },
        project: {
            get: "projects/",
            getAll: "projects/all",
            create: "projects/create",
            update: "projects/update/",
            delete: "projects/delete/",
            pdf: {
                create: "projects/pdf/",
                get: "projects/getPdf/",
            },
            email: {
                send: "projects/send-mail/"
            },
            clone: "projects/clone/",
            getFiltered: "projects/filtered",
            deleteProjectFile: 'projects/delete-project-file/',
        },
        product: {
            get: "products/",
            getAll: "products/all",
            create: "products/create",
            update: "products/update/",
            delete: "products/delete/",
            variation: {
                create: "products/variations/create/",
                update: "products/variations/update/",
                delete: "products/variations/delete/",
            }
        },
    }
};


export default globals;